import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PopularPost from "../components/PopularPost";
import RelatedPost from "../components/RelatedPost";
import { getData } from "../helper/helper";

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  locale,
  category,
  featuredimage,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  const blog = getData(locale).blogPage;

  return (
    <section className="subPage ">
      {helmet || ""}
      <div className="image">
        <Img
          fluid={featuredimage.childImageSharp.fluid}
          alt={title}
          title={title}
          className="post-image"
        />
        <div className="header-titles">
          <p>{category}</p>
          <h1>{title}</h1>
        </div>
      </div>
      <div className="container content">
        <div className="main">
          <div className="content">
            <PostContent content={content} />
          </div>
        </div>
        <div className="aside">
          <div className="popularPosts">
            <PopularPost locale={locale} />
          </div>
        </div>
      </div>
      <div className="relatedPosts">
        <h5>{blog.related}</h5>
        <div className="container blogs">
          <RelatedPost />
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  metatitle: PropTypes.string,
  locale: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  featuredpost: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout locale={post.frontmatter.locale}>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet>
            <title>{`${
              post.frontmatter.metatitle
                ? post.frontmatter.metatitle
                : post.frontmatter.title
            }`}</title>
            {post.frontmatter.metatitle && (
              <meta name="title" content={`${post.frontmatter.metatitle}`} />
            )}
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        category={post.frontmatter.category}
        locale={post.frontmatter.locale}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        metatitle
        description
        category
        featuredpost
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        locale
      }
    }
  }
`;
