import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { getLanguage, getData } from "../helper/helper";

const PopularPost = () => {
  const locale = getLanguage();
  const blogPage = getData(locale).blogPage;
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query PopularPost($locale: String) {
        allMarkdownRemark(
          limit: 10
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              pageKey: { eq: "page_blogpost" }
              locale: { eq: $locale }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                locale
                pageKey
                category
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <>
      <h1>{blogPage.popular}</h1>
      {allMarkdownRemark.edges
        .filter(
          ({ node: post }) =>
            post.frontmatter.featuredpost && post.frontmatter.locale === locale
        )
        .slice(0, 5)
        .map(({ node: post }) => (
          <div key={post.fields.slug}>
            <div className="column">
              <div
                className="thumbnail"
                style={{
                  backgroundImage: `url(${
                    !!post.frontmatter.featuredimage.childImageSharp
                      ? post.frontmatter.featuredimage.childImageSharp.fluid.src
                      : post.frontmatter.featuredimage
                  })`,
                }}
              />

              <Link
                className="title"
                to={`${post.fields.slug}`}
                title={post.frontmatter.title}
              >
                {post.frontmatter.title}
                <span>{blogPage.read}</span>
              </Link>
            </div>
          </div>
        ))}
    </>
  );
};

export default PopularPost;
